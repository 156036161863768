/*
    Store small, common utility functions here
*/

declare global {
    interface Window {
        digitalData: {
            page: {
                attribute: {
                    isPageLoadVariables: boolean
                },
                pageInfo: {
                    pageName: string
                },
                event: object
            }
        },
        _satellite: any
    }
}

const trackEvent = obj => {
    // reference window digital data object
    const digitalData = window.digitalData = window.digitalData || {}
    digitalData.page.pageInfo.pageName = obj.pageName
    // set page load variable
    digitalData.page.attribute.isPageLoadVariables = obj.isPageLoadVariables ? true : false
    // figure out event
    let event = {}
    let trackString = obj.isPageLoadVariables ? 'savingscalculator' : 'send-Event'
    if (obj.eventAction) {
        event = {
            eventInfo: {
                eventAction: obj.eventAction,
                eventPage: obj.pageName,
                scSelection: obj.scSelection
            }
        }
    }
    digitalData.page.event = event
    if (process.env.NODE_ENV !== 'production') console.log(JSON.parse(JSON.stringify(digitalData)))
    //console.log(JSON.parse(JSON.stringify(digitalData)))
    // send track command
    if (window._satellite) {
        window._satellite.track(trackString)
        if (process.env.NODE_ENV !== 'production') console.log('fired: _satellite.track("' + trackString + '")')
    }
}

const trackEventWithTesting = obj => {
    // reference window digital data object
    const digitalData = window.digitalData = window.digitalData || {}
    digitalData.page.pageInfo.pageName = obj.pageName
    // set page load variable
    digitalData.page.attribute.isPageLoadVariables = obj.isPageLoadVariables ? true : false
    // figure out event
    let event = {}
    let trackString = obj.isPageLoadVariables ? 'savingscalculator' : 'send-Event'
    if (obj.eventAction) {
        event = {
            eventInfo: {
                eventAction: obj.eventAction,
                eventPage: obj.pageName,
                scSelection: obj.scSelection,
                variation: obj.testingGroup
            }
        }
    }
    digitalData.page.event = event
    if (process.env.NODE_ENV !== 'production') console.log(JSON.parse(JSON.stringify(digitalData)))
    console.log(JSON.parse(JSON.stringify(digitalData)))
    // send track command
    if (window._satellite) {
        window._satellite.track(trackString)
        if (process.env.NODE_ENV !== 'production') console.log('fired: _satellite.track("' + trackString + '")')
    }
}

const trackCompetitorLabel = str => {

    if (!str) {
        return '';
    }

    return `|xmobile vs ${str}`

}

export {
    trackCompetitorLabel,
    trackEvent,
    trackEventWithTesting
};

/* 
* exampleEvent = {
    eventInfo: {
        eventAction: "Savings Confirmation",
        eventPage: "xmobile|savingscalculator|savings confirmation",
        scSelection: "SC:savings=$18|recplan:sharedlines=2:shareddata=3:unlimitedlines=2|currentprovider:provider=Verizon",
        variation: "Personalized Non-Branded Header + Value-Add Footer"
    }
}
*/