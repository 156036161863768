import { merge } from 'lodash';
import content from './content.json';
import contentVerizon from './content-verizon.json';
import contentTMobile from './content-tmobile.json';
import contentATT from './content-att.json';

/**
 * Returns website content JSON dependant on competitor
 *
 * @param {string} competitor
 * @returns {object}
 */
export const getData = (competitor = '') => {
    switch(competitor) {
        case 'verizon':
            merge(content, contentVerizon);
            break;
        case 'tmobile':
            merge(content, contentTMobile);
            break;
        case 'att':
            merge(content, contentATT);
            break;
    }
    return content;
}