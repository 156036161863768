import { useState } from 'react';
import { trackCompetitorLabel, trackEvent } from '../../utils/utils';
import Modal from 'react-modal';
import iconClose from "../../assets/img/icon-close.svg";

const ResultsFooter = ({content, calculationValues, competitor, dtmData}) => {

    const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false);
    const handleDisclaimerModalOpen = () => {
        setDisclaimerModalOpen(true);
    };
    const handleDisclaimerModalClose = () => {
        setDisclaimerModalOpen(false);
    };

    let scSelectionSavings = calculationValues.recommended.monthlySavings;
    let scSelectionAnalytics = `SC:savings=${scSelectionSavings.toFixed(2)}|recplan:amount=${calculationValues.recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
    if (scSelectionSavings <= 0) {
        scSelectionAnalytics = "SC:savings=no savings";
    }

    return (
        <>
            {['promo-with-3up', 'promo-without-3up'].includes(dtmData.savingsPromo) && content.savingsPromo.content &&
                <section className='calculator__savings-promo'>
                    <div className='calculator__savings-promo-inner'>
                        <div className='calculator__savings-promo-content'>
                            <div className="calculator__savings-promo-eyebrow">{content.savingsPromo.eyebrow}</div>
                            <h4 className='calculator__savings-promo-headline'>{content.savingsPromo.content}</h4>
                            <div className='calculator__savings-promo-buttons'>
                                <a
                                    className='button button__primary'
                                    href={content.savingsPromo.linkUrl}
                                    target='_blank'
                                    rel='noreferrer'
                                    onClick={() => trackEvent({
                                        pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                                        eventAction: content.savingsPromo.linkText,
                                        scSelection: `SC:savings=${scSelectionSavings.toFixed(2)}|recplan:amount=${calculationValues.recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
                                    })}>
                                    {content.savingsPromo.linkText}
                                </a>
                                <button className='button__tertiary'
                                    onClick={handleDisclaimerModalOpen}>
                                    <span>{content.savingsPromo.disclaimerLinkText}</span>
                                </button>
                                <Modal
                                    isOpen={disclaimerModalOpen}
                                    onRequestClose={handleDisclaimerModalClose}
                                    ariaHideApp={false}
                                    contentLabel="Disclaimer"
                                    overlayClassName={"modal__overlay"}
                                    className={"modal__content"}
                                    bodyOpenClassName={"modal__body--open"}
                                >
                                    <button className='modal__close-button'
                                        onClick={handleDisclaimerModalClose}
                                        aria-label="Close dialog"
                                    >
                                        <img src={iconClose} alt='' role='img' />
                                    </button>
                                    <div dangerouslySetInnerHTML={{__html: content.savingsPromo.disclaimerContent}}></div>
                                </Modal>
                            </div>
                        </div>
                        <div className='calculator__savings-promo-media'>
                            <img src={require('../../assets/img/'+content.savingsPromo.image).default} alt={content.savingsPromo.content} />
                        </div>
                    </div>
                </section>
            }

            {dtmData.savingsPromo !== 'promo-without-3up' &&
                <section className='calculator__show-alt'>
                    {content.resultsTripleCallout.heading &&
                        <h4 className='calculator__alt-sub-headline'>{content.resultsTripleCallout.heading}</h4>
                    }
                    <div className='calculator__alt-flex'>
                        <div className='calculator__alt-row'>
                            <div className='calculator__alt-icon'>
                                <img src={require('../../assets/img/'+content.resultsTripleCallout.icon1).default} alt='' role='presentation'></img>
                            </div>
                            <div className='calculator__alt-content'>
                                <h5>{content.resultsTripleCallout.title1}</h5>
                                {content.resultsTripleCallout.body1}
                            </div>
                        </div>
                        <div className='calculator__alt-row'>
                            <div className='calculator__alt-icon'>
                                <img src={require('../../assets/img/'+content.resultsTripleCallout.icon2).default} alt='' role='presentation'></img>
                            </div>
                            <div className='calculator__alt-content'>
                                <h5>{content.resultsTripleCallout.title2}</h5>
                                {content.resultsTripleCallout.body2}
                            </div>
                        </div>
                        <div className='calculator__alt-row'>
                            <div className='calculator__alt-icon'>
                                <img src={require('../../assets/img/'+content.resultsTripleCallout.icon3).default} alt='' role='presentation'></img>
                            </div>
                            <div className='calculator__alt-content'>
                                <h5>{content.resultsTripleCallout.title3}</h5>
                                {content.resultsTripleCallout.body3}
                            </div>
                        </div>
                    </div>
                </section>
            }

            {!['promo-with-3up', 'promo-without-3up'].includes(dtmData.savingsPromo) && content.savingsButton.linkText &&
                <div className="calculator-footer__shipping">                  
                    <a
                        className="button button__secondary"
                        href={content.savingsButton.linkUrl}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => trackEvent({
                            pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                            eventAction: `promo:${content.savingsButton.linkText}`,
                            scSelection: scSelectionAnalytics,
                        })}               
                    >{content.savingsButton.linkText} <span className="visually-hidden"> Opens in New Window</span>
                    </a> 
                </div>
            }
        </>
    )
}

export default ResultsFooter;