interface DataUsage {
  unlimitedLines: number,
  unlimitedPlusLines: number
}

interface RecommendedInfo {
  monthlySavings: number,
  yearlySavings: number,
  unlimitedPrice: number,
  unlimitedPlusPrice: number,
  total: number
}

interface CalculationValues {
  numberOfLines: number,
  userEnteredDataUsage: Array<'unlimitedPlus' | 'unlimited'>, // the current plan that the user entered
  dataUsage: DataUsage, // recommended plan
  monthlyBill: number,
  currentProvider: string,
  recommended: RecommendedInfo
}

// TODO combine userEnteredDataUsage and dataUsage. the dataUsage (which would be more aptly named recommendedDataUsage), is immediately determined based on the new quiz results
// dataUsage at this point is essentially a tally of the occurences of 'unlimited' and 'unlimitedPlus' in the userEnteredDataUsage array
export const initialCalculationValues: CalculationValues = {
  numberOfLines: 1,
  userEnteredDataUsage: ['unlimitedPlus'],
  dataUsage: {
    unlimitedLines: 0,
    unlimitedPlusLines: 1,
  },
  monthlyBill: 0,
  currentProvider: 'not selected',
  recommended: {
    monthlySavings: 0,
    yearlySavings: 0,
    unlimitedPrice: 0,
    unlimitedPlusPrice: 0,
    total: 0,
  },
};

/**
 * split data bucket selections into Unlimited (< 30GB) and Unlimited Plus (< 50 GB)
 *
 * Used to be more involved by picking a combination of unlimited lines and shared data lines
 * that meets the user's data needs selected in <DataUsage /> for the lowest possible price
 *
 * @param allLinesDataUse
 * @returns dataUsage
 */
export const getRecommendedDataPlan = (allLinesDataUse: Array<'unlimitedPlus' | 'unlimited'>) => {
  let unlimitedLines: number = 0;
  let unlimitedPlusLines: number = 0;

  allLinesDataUse.forEach(line => {
    if (line === 'unlimited') {
      unlimitedLines++;
    } else {
      unlimitedPlusLines++;
    }
  });

  const dataUsage: DataUsage = {
    unlimitedLines,
    unlimitedPlusLines
  };

  return dataUsage;
}

export const getRecommendedPlan = (dataUsage: DataUsage, monthlyBill: number) => {
  const getPricing = (unlimitedLines: number, unlimitedPlusLines: number): [number, number] => {
    /**
     * First Unlimited Line: $40
     * Additional Unlimited Line: $20
     *
     * First Unlimited Plus Line: $50
     * Additional Unlimited Plus Line: $30
     *
     * When there are both types of lines, let's assume the
     * first line was a regular Unlimited line for $40
     */
    let unlimitedPrice: number = 0;
    let unlimitedPlusPrice: number = 0;

    if (unlimitedLines > 0) {
      unlimitedPrice += 40;
      unlimitedPrice += (unlimitedLines - 1) * 20;
      if (unlimitedPlusLines > 0) {
        unlimitedPlusPrice += unlimitedPlusLines * 30;
      }
    } else if (unlimitedPlusLines > 0) {
      unlimitedPlusPrice += 50;
      unlimitedPlusPrice += (unlimitedPlusLines - 1) * 30;
    }

    return [unlimitedPrice, unlimitedPlusPrice];
  };

  const [unlimitedPrice, unlimitedPlusPrice] = getPricing(dataUsage.unlimitedLines, dataUsage.unlimitedPlusLines);
  const total: number = unlimitedPrice + unlimitedPlusPrice;
  const monthlySavings: number = monthlyBill - total;
  const yearlySavings: number = monthlySavings * 12;

  const recommended: RecommendedInfo = {
    monthlySavings,
    yearlySavings,
    unlimitedPrice,
    unlimitedPlusPrice,
    total
  };

  return recommended;
}
