const TripleCallout = ({
   tripleCallout={
       heading: '',
       icon1: '',
       body1: '',
       icon2: '',
       body2: '',
       icon3: '',
       body3: ''
   }
}) => {
    return (
        <section className='calculator__triple-callout'>
            <div className='calculator__triple-callout-inner'>
                <h3>{tripleCallout.heading}</h3>
                <div className='calculator__triple-callout-items'>
                    <div className='calculator__triple-callout-item'>
                        <img src={require('../../assets/img/'+tripleCallout.icon1).default} alt="" />
                        <p>{tripleCallout.body1}</p>
                    </div>
                    <div className='calculator__triple-callout-item'>
                        <img src={require('../../assets/img/'+tripleCallout.icon2).default} alt="" />
                        <p>{tripleCallout.body2}</p>
                    </div>
                    <div className='calculator__triple-callout-item'>
                        <img src={require('../../assets/img/'+tripleCallout.icon3).default} alt="" />
                        <p>{tripleCallout.body3}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TripleCallout;