import * as React from 'react'
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import { Calculator } from './pages/calculator'
import { PageNotFound } from './components/404'


export const AppRouter = ({ dtmData }) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* New route without /mobile/learn/ */}
        <Route
          exact
          path="/learn/mobile/savings-calculator"
          render={(props) => <Calculator dtmData={dtmData} {...props} />}
        />
        {/* Supporting the old route with /mobile/learn/ and additional segments */}
        <Route
          exact
          path="/mobile/learn/savings-calculator/xfinity-mobile-vs-:competitor(verizon|att|tmobile)"
          render={(props) => <Calculator dtmData={dtmData} {...props} />}
        />
        {/* Redirect to new /savings-calculator route */}
        <Redirect
          exact
          from="/mobile/learn/savings-calculator*"
          to="/learn/mobile/savings-calculator"
        />
        {/* Default route for any other paths */}
        <Route path="*">
          <PageNotFound status={404} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

