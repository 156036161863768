//import { useEffect, useState } from 'react'
import logo from '../assets/img/xfinity-black.svg'
import logoWhite from '../assets/img/xfinity.svg'

function Header({allContent, dtmData}) {
    const content = allContent.header

    /*
    const [localeLink, setLocaleLink] = useState(<div/>)
    useEffect(() => {
        const host = window.location.host
        let altHost: String

        if (process.env.NODE_ENV === 'development') {
            if (locale === 'es') altHost = host.replace('es.', '')
            else altHost = 'es.' + host
        }
        else {
            if (locale === 'es') altHost = host.replace('es', 'www')
            else altHost = host.replace('www', 'es')
        }
        const fullPath = window.location.protocol + '//' + altHost + window.location.pathname

        setLocaleLink(<a href={fullPath}>{locale === 'en' ? 'Español' : 'English'}</a>)
    }, [locale])
    */


    return (
        <header className="calculator-header">
            <div className="calculator-header__wrapper">
                <a href="https://www.xfinity.com/mobile/" className="calculator-header__logo">
                    <img src={`${dtmData.isDesignVariation ? logo : logoWhite}`} alt={content.heading}></img>
                </a>
                <div className="calculator-header__locale">
                    {/* {localeLink} */}
                    <a href="https://xfinity.com/learn/mobile/savings-calculator">EN</a>|
                    <a href="https://es.xfinity.com/learn/mobile/savings-calculator">ES</a>
                </div>
            </div>
        </header>
    )
}

export default Header
