import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getRecommendedPlan, getRecommendedDataPlan } from '../../utils/calculator';
import { trackCompetitorLabel, trackEvent } from '../../utils/utils';
import Lines from './Lines';
import DataUsage from './DataUsage';
import MonthlyBill from './MonthlyBill';
import PageHeading from './PageHeading';
import Disclaimer from '../reusable/Disclaimer';

const CurrentPlan = ({
  setStage,
  setPast,
  content,
  calculationValues,
  setCalculationValues,
  dtmData
}) => {

  //#region Analytics
  const { competitor } = useParams<{ competitor?: string }>();
  useEffect(() => {
    // fire event on page load
    trackEvent({
      pageName: `xmobile|savingscalculator|savings calculator${trackCompetitorLabel(competitor)}`,
      isPageLoadVariables: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion Analytics

  //#region <Lines />
  const [lines, setLines] = useState(calculationValues.numberOfLines);
  useEffect(() => {
    setCalculationValues((prevState) => ({
      ...prevState,
      numberOfLines: lines,
    }));
  }, [lines, setCalculationValues]);
  //#endregion <Lines />

  //#region <DataUsage />
  const [usageValue, setUsageValue] = useState(2);
  /**
   * Default all lines to unlimited
   */
  useEffect(() => {
    const unlimitedLines = Array(lines).fill('unlimited');
    setCalculationValues((prevState) => ({
      ...prevState,
      userEnteredDataUsage: unlimitedLines,
    }));
  }, [lines, setCalculationValues]);
  //#endregion <DataUsage />
  
  //#region <MonthlyBill />
  const [monthlyBill, setMonthlyBill] = useState(calculationValues.monthlyBill);
  const [currentProvider, setCurrentProvider] = useState(
    calculationValues.currentProvider
  );
  useEffect(() => {
    setMonthlyBill(parseInt(monthlyBill));
    setCalculationValues((prevState) => ({
      ...prevState,
      monthlyBill: monthlyBill,
    }));
    if (currentProvider !== 'not selected') {
      setCalculationValues((prevState) => ({
        ...prevState,
        currentProvider: currentProvider,
      }));
    }
  }, [monthlyBill, currentProvider, setCalculationValues]);
  const options = content.currentProviderArray;
  let selectValues;
  if (dtmData.isDesignVariation) {
    selectValues = options.map((option) => {
      return { value: option, label: option };
    });
    selectValues[0].value = 'not selected';
  } else {
    selectValues = options.map((option) => {
      return option;
    });
    selectValues[0] = 'not selected';
  }
  const getOptionLabel = (val) => {
    const label = selectValues.find((option) => {
      return option.value === val;
    });
    return label ? label.label : '';
  };
  const defaultLabel = getOptionLabel(calculationValues.currentProvider);
  const defaultOption = [
    { value: calculationValues.currentProvider, label: defaultLabel },
  ];
  const defaultProvider = calculationValues.currentProvider;
  //#endregion <MonthlyBill />

  //#region form handling
  const isDisabled = useMemo(() => {
    return !lines || !monthlyBill;
  }, [lines, monthlyBill]);

  /**
   * On form submission:
   * 1. Calculate recommended plan and potential savings and update CalculationValues state
   * 2. Use the previous results to update stage
   */
  const submitForm = e => {
    e.preventDefault();
    let usageAnalytics = "moderate";
    switch (usageValue) {
      case 1:
        usageAnalytics = "light";
        break;
      case 2:
        usageAnalytics = "moderate";
        break;
      case 3:
        usageAnalytics = "heavy";
        break;
    }
    
    trackEvent({
      pageName: "xmobile|savingscalculator|savings calculator",
      eventAction: content.viewSavings,
      scSelection: `SC:lines=${lines}|usage=${usageAnalytics}|bill=${monthlyBill.toFixed(2)}|provider=${currentProvider}`,
    });
    // first, get the recommended data plan based on the userEnteredDataUsage
    const recommendedDataUsage = getRecommendedDataPlan(calculationValues.userEnteredDataUsage);
    // Get the savings results of that recommended plan
    const recommendedPlanSavings = getRecommendedPlan(recommendedDataUsage, monthlyBill);

    setCalculationValues((prevState) => ({
      ...prevState,
      dataUsage: recommendedDataUsage,
      recommended: recommendedPlanSavings,
    }));

    // update calculator state
    setPast('currentPlan');
    setStage(recommendedPlanSavings.monthlySavings > 0 ? 'showSavings' : 'showAlt');
  };
  //#endregion form handling

  //#region first interaction
  const isFirstInteractionRef = useRef(true);
  const handleFirstInteraction = placement => {
    if (isFirstInteractionRef.current === true) {
      isFirstInteractionRef.current = false;
      console.log('placement', placement)
      trackEvent({
        pageName: `xmobile|savingscalculator|savings calculator`,
        eventAction: 'firstinteraction',
        scSelection: `SC:placement=${placement}`
      });
    }
  }

  const handleSetLines = lines => { 
    handleFirstInteraction('number of lines');
    setLines(lines);
  };

  const handleSetUsageValue = (usage, isBrowserResize) => { 
    if (!isBrowserResize) {
      handleFirstInteraction('data usage');
    }
    setUsageValue(usage);
  };

  const handleSetMonthlyBill = bill => { 
    handleFirstInteraction('monthly bill');
    setMonthlyBill(bill);
  };

  const handleSetCurrentProvider = provider => {
    handleFirstInteraction('current provider');
    setCurrentProvider(provider);
  }
  //#endregion first interaction


  return (
    <>
      <Helmet>
        <title>{content.metaTitle}</title>
      </Helmet>
      <PageHeading content={content} dtmData={dtmData} />
      <form
        onSubmit={(e) => {
          submitForm(e);
        }}
        className='form calculator__form'
      >
        <Lines content={content} lines={lines} setLines={handleSetLines} />
        <DataUsage content={content} value={usageValue} setValue={handleSetUsageValue} />
        <MonthlyBill
          content={content}
          monthlyBill={monthlyBill}
          setMonthlyBill={handleSetMonthlyBill}
          currentProvider={currentProvider}
          setCurrentProvider={handleSetCurrentProvider}
          selectValues={selectValues}
          defaultOption={defaultOption}
          defaultProvider={defaultProvider}
          dtmData={dtmData}
        />
        <div className='calculator__button-wrap'>
          <button className='button' type='submit' disabled={isDisabled}>
            {content.viewSavings}
          </button>
        </div>
      </form>
      <Disclaimer content={content} dtmData={dtmData} />
    </>
  );
};

export default CurrentPlan;