const Disclaimer = ({content, dtmData}) => {
    return (
        <>
            {content.savingsDisclaimer !== '' &&
                <div className='calculator__disclaimer'>
                    <p dangerouslySetInnerHTML={{__html: content.savingsDisclaimer}}></p>
                    {dtmData.moreReasonsToSwitchBanner === 'singleCallout1' &&
                        <p>{content.moreReasonsToSwitchBanners.singleCallout1.disclaimer}</p>
                    }

                    {dtmData.moreReasonsToSwitchBanner === 'singleCallout2' &&
                        <p>{content.moreReasonsToSwitchBanners.singleCallout2.disclaimer}</p>
                    }

                    {dtmData.moreReasonsToSwitchBanner === 'singleCallout3' &&
                        <p>{content.moreReasonsToSwitchBanners.singleCallout3.disclaimer}</p>
                    }

                    {dtmData.moreReasonsToSwitchBanner === 'tripleCallout1' &&
                        <p>{content.moreReasonsToSwitchBanners.tripleCallout1.disclaimer}</p>
                    }

                    {dtmData.moreReasonsToSwitchBanner === 'tripleCallout2' &&
                        <p>{content.moreReasonsToSwitchBanners.tripleCallout2.disclaimer}</p>
                    }

                    {dtmData.moreReasonsToSwitchBanner === 'tripleCallout3' &&
                        <p>{content.moreReasonsToSwitchBanners.tripleCallout3.disclaimer}</p>
                    }
                </div>
            }
        </>
    )
}

export default Disclaimer;