// import CompetitorPricing from '../reusable/CompetitorPricing'
import SingleCallout from '../reusable/SingleCallout';
import TripleCallout from '../reusable/TripleCallout';

const PageHeading = ({ content, dtmData}) => {
  // const variation = content.optimizationTesting.savingsHeader

  return (
    <div className='calculator__page-heading'>

      {dtmData.isDesignVariation 
        ? <div className={`calculator__banner ${content.pageHeadingImage ? 'calculator__banner--image' : ''}`}>
              { content.pageHeadingImage &&
                <picture className='calculator__banner-media'>
                  <img src={require('../../assets/img/'+content.pageHeadingImage).default} alt='Stylized mobile phone' />
                </picture>
              }
          <div className='calculator__banner-content'>
              <div className='calculator__banner-content-inner'>
                  { content.pageHeadingBadge &&
                  <picture className='calculator__banner-badge'>
                      <img src={require('../../assets/img/'+content.pageHeadingBadge).default} alt={content.pageHeadingSecondary} />
                  </picture>
                  }
                <div className={`calculator__banner-text ${content.pageHeadingBadge ? 'calculator__banner-text--long' : ''}`}>
                  <h1 className='calculator__main-heading'>{content.pageHeadingMain}</h1>
                  <h2 className='calculator__secondary-heading'>{content.pageHeadingSecondary}</h2>
                </div>
              </div>
            </div>
          </div>
        : <div className='calculator__banner'>
            <h1 className='calculator__main-heading'>{content.pageHeadingMain}</h1>
            <h2 className='calculator__secondary-heading'>{content.pageHeadingSecondary}</h2>
          </div>
      }

        {dtmData.moreReasonsToSwitchBanner === 'singleCallout1' &&
            <SingleCallout singleCallout={content.moreReasonsToSwitchBanners.singleCallout1} />
        }

        {dtmData.moreReasonsToSwitchBanner === 'singleCallout2' &&
            <SingleCallout singleCallout={content.moreReasonsToSwitchBanners.singleCallout2} />
        }

        {dtmData.moreReasonsToSwitchBanner === 'singleCallout3' &&
            <SingleCallout singleCallout={content.moreReasonsToSwitchBanners.singleCallout3} />
        }

        {dtmData.moreReasonsToSwitchBanner === 'tripleCallout1' &&
            <TripleCallout tripleCallout={content.moreReasonsToSwitchBanners.tripleCallout1} />
        }

        {dtmData.moreReasonsToSwitchBanner === 'tripleCallout2' &&
            <TripleCallout tripleCallout={content.moreReasonsToSwitchBanners.tripleCallout2} />
        }

        {dtmData.moreReasonsToSwitchBanner === 'tripleCallout3' &&
            <TripleCallout tripleCallout={content.moreReasonsToSwitchBanners.tripleCallout3} />
        }

      { content.pageHeadingTertiary &&
        <section className='calculator__tertiary-heading-container'>
          <h3 className='calculator__tertiary-heading'>{content.pageHeadingTertiary}</h3>
        </section>
      }
    </div>
  )
}

export default PageHeading
