const SingleCallout = ({
    singleCallout= {
        image: '',
        heading: '',
        content: ''
    }
}) => {
    return (
        <section className='calculator__single-callout'>
            <div className='calculator__single-callout-inner'>
                <img src={require('../../assets/img/'+singleCallout.image).default} alt={singleCallout.heading} />
                <div className='calculator__single-callout-content'>
                    <h3>{singleCallout.heading}</h3>
                    <p>{singleCallout.content}</p>
                </div>
            </div>
        </section>
    )
}

export default SingleCallout;