const Select = ({options, metaData, handleChange, defaultProvider, values}) => {
    const selectOptions = options.map((option,index) => {
        if (index === 0) {
            return <option key={index} disabled={true} value={values[index]}>{option}</option>
        }
        return <option key={index} value={values[index]}>{option}</option>
    })
    return (
        
        <select className="calculator__data-select" id={metaData.id} onChange={handleChange} defaultValue={defaultProvider ? defaultProvider : options[0]}>{selectOptions}</select>
            
    )
}

export default Select;