function Footer({allContent, pageName, calculationValues, dtmData}) {
    const content = allContent.footer
    const footerLinks = content.footerNav;
    var navLinks = footerLinks.map(function(item, index) {
        return (
            <li key={index} className={`calculator-footer__nav--item ${item.isAdChoice === true ? "ad-choice": ""}`}>
                <a href={item.url}>{item.linkText}</a>
            </li>
        )
    });
    let today = new Date();
    const year = today.getFullYear();

    return (
        <footer className="calculator-footer">
            <ul className='calculator-footer__nav'>
                {navLinks}
            </ul>
            <div className="calculator-footer__disclaimer">Comcast © {year}. {content.legalStatic}</div>
        </footer>
    )
}

export default Footer
