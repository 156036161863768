import { useState } from 'react'
import CalculatorLayout from '../../layouts/CalculatorLayout'
import { ContentContextConsumer } from '../../lib/ContentContext'
import { Base } from '../../components/calculator/Base'
import {initialCalculationValues} from '../../utils/calculator'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export const Calculator = props => {

    const [stage, setStage] = useState('currentPlan');
    const [calculationValues, setCalculationValues] = useState(initialCalculationValues);

    let isSpanishSubdomain = window.location.hostname.includes('es.');
    if (props.dtmData.isSpanish) {
        isSpanishSubdomain = true;
    }
    return (
        <CalculatorLayout>
            <ContentContextConsumer>
                {appContext =>
                    appContext && (
                        <div className={`${props.dtmData.isDesignVariation ? 'calculator-wrap--variation' : 'calculator-wrap--standard'} ${isSpanishSubdomain ? 'calculator-wrap--es' : 'calculator-wrap--en'}`}>
                            <Header allContent={appContext} dtmData={props.dtmData} />
                            <main>
                                <div className="calculator">
                                    <Base allContent={appContext} stage={stage} setStage={setStage} dtmData={props.dtmData} calculationValues={calculationValues} setCalculationValues={setCalculationValues} />
                                </div>
                            </main>
                            <Footer allContent={appContext} pageName={stage} calculationValues={calculationValues} dtmData={props.dtmData} />
                        </div>
                    )
                }
            </ContentContextConsumer>
        </CalculatorLayout>
    )
}