import { useRef } from 'react';

const Lines = ({ content, lines, setLines }) => {
  const announceLineNumber = useRef<HTMLDivElement>(null);
  //runs on plus button click updates aria announcement to tell user the current number of lines
  const announceAddLines = () => {
    if (announceLineNumber.current != null) {
      announceLineNumber.current.innerHTML = 'Current Number of Lines is ' + (lines + 1);
    }
  };
  //runs on minus button click updates aria announcement to tell user the current number of lines
  const announceSubtractLines = () => {
    if (announceLineNumber.current != null) {
      announceLineNumber.current.innerHTML = 'Current Number of Lines is ' + (lines - 1);
    }
  };

  return (
    <>
      <section className='calculator__lines'>
        <p className='calculator__form--label'>{content.linesLabel}</p>
        <div className='calculator__lines-edit'>
          <button
            className='calculator__lines-btn'
            type='button'
            disabled={lines === 1}
            onClick={() => {
              setLines(lines - 1);
              announceSubtractLines();
            }}
          >
            <span>&#8722;</span>
            <span className='visually-hidden'>Reduce lines needed</span>
          </button>
          <label htmlFor='numberOfLines' className='visually-hidden'>
            Use the Plus and Minus buttons to {content.linesLabel}
          </label>
          <input className='calculator__lines-number' aria-readonly='true' readOnly id='numberOfLines' name='numberOfLines' type='number' max={10} autoComplete='off' value={lines} onChange={(e) => setLines(parseInt(e.target.value))} />
          <div aria-live='polite' ref={announceLineNumber} className='visually-hidden'></div>
          <button
            className='calculator__lines-btn'
            type='button'
            disabled={lines === 10}
            onClick={() => {
              setLines(lines + 1);
              announceAddLines();
            }}
          >
            <span>&#43;</span>
            <span className='visually-hidden'>Increase lines needed</span>
          </button>
        </div>

        <div className='calculator__lines-disclaimer'>{content.linesDisclaimer}</div>

      </section>
    </>
  );
};

export default Lines;
