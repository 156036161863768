
declare global {
    interface Window {
        AdobeTeamUtils: any;
    }
}

const getAdobeTeamUtils = () => {
    return new Promise((resolve, reject) => {
        let intervalCount: number = 0;
        const interval = setInterval(() => {
            if (window.AdobeTeamUtils) {
                clearInterval(interval);
                resolve(null);
            } else if (intervalCount >= 20) {
                clearInterval(interval);
                reject();
            }
            intervalCount++;
        }, 100);
    });
};

const fetchAdobeTeamUtils = () => {
    return window.AdobeTeamUtils.getPrefetchedOfferPromise({
        mboxList: window.AdobeTeamUtils.getCurrentViewMboxList(),
    }).then(data => {
        const base = {
            isInMonthly: false,
            isInYearly: false,
            isDesignVariation: true,
            isABTest: false,
            savingsPromo: '',
            moreReasonsToSwitchBanner: ''
        };
        if (data.length === 0) {
            return base;
        }
        let mergedData = {};
        data.forEach(item => {
            mergedData = {...base, ...item};
        })
        return mergedData;
    });
};

export {
    getAdobeTeamUtils,
    fetchAdobeTeamUtils
};