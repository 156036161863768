import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { trackCompetitorLabel, trackEvent } from '../../utils/utils';
import { Helmet } from 'react-helmet';
import { initialCalculationValues } from '../../utils/calculator';
import { getAdobeTeamUtils, fetchAdobeTeamUtils } from '../../utils/adobe';
import icon5GSimple from '../../assets/img/icon-5G-simple.svg';
import iconWifiTower from '../../assets/img/icon-wifi-tower.svg';
import iconPlay from '../../assets/img/icon-play.svg';
import ResultsFooter from '../reusable/ResultsFooter';

const ShowSavings = ({ setStage, setPast, content, calculationValues, setCalculationValues, dtmData }) => {
  const { competitor } = useParams<{ competitor?: string }>();
  const recommended = calculationValues.recommended;
  const [isLoading, setIsLoading] = useState(true);
  const totalLines = calculationValues.dataUsage.unlimitedLines + calculationValues.dataUsage.unlimitedPlusLines;

  // initial load
  useEffect(() => {
    // restore previous value
    // if (calculationValues.monthlyBill) setValue('monthlyBill', calculationValues.monthlyBill)
    trackEvent({
      pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
      eventAction: 'Savings Confirmation',
      scSelection: 'SC:savings=' + recommended.monthlySavings.toFixed(2) + '|recplan:totalcost=' + recommended.total.toFixed(2) + ':unlimitedlines=' + calculationValues.dataUsage.unlimitedLines + ':unlimitedPlusLines=' + calculationValues.dataUsage.unlimitedPlusLines,
      isPageLoadVariables: true,
    });

    getAdobeTeamUtils()
      .then(fetchAdobeTeamUtils)
      .then(data => {
        if(data.isABTest) {
          dtmData.isABTest = data.isABTest;
        }
        if(data.moreReasonsToSwitchBanner) {
          dtmData.moreReasonsToSwitchBanner = data.moreReasonsToSwitchBanner;
        }
        if(data.savingsPromo) {
          dtmData.savingsPromo = data.savingsPromo;
        }
        setIsLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetCalculator = () => {
    setCalculationValues(() => initialCalculationValues);
    setPast('savings');
    setStage('currentPlan');
  };

  return (
    <>
      <Helmet>
        <title>{content.metaTitle}</title>
      </Helmet>
      <section className='calculator__page-heading calculator__page-heading--savings'>
        <h1 className='calculator__secondary-heading'>
          {content.savingsHeadingSecondary}
        </h1>
        <h2 className='calculator__subtitle'>{content.savingsSubtitle}</h2>
        <div className='calculator__disclaimer-reset-block'>
          <p id='total-disclaimer' className='calculator__total-disclaimer'>
            <a
              className='link-external'
              href={content.savingsTotalDisclaimerLink}
              target='_blank'
              rel='noreferrer'
              onClick={() => trackEvent({
                pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                eventAction: 'Xfinity Internet Required',
                scSelection: `SC:savings=${recommended.monthlySavings.toFixed(2)}|recplan:amount=${recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
              })}
            >
              {content.savingsTotalDisclaimer} <span className='visually-hidden'> Opens in New Window</span>
            </a>
          </p>
        </div>
      </section>
      <section className={`calculator__show-savings ${isLoading ? ' calculator__show-savings--is-loading' : ''}`}>
        <div className='calculator__savings-banner'>
          <div className='calculator__savings-block'>
            <div className="calculator__savings-eyebrow">Save</div>
            <div className="calculator__savings-amount">
              <sup className='calculator__savings-amount--currency'>$</sup>
              {recommended.monthlySavings.toFixed(0)}
            </div>
            <div className='calculator__savings-time'>{content.savingsMonthly}</div>
            {/* <div className="calculator__savings-plan">{content.savingsPlan}</div> */}
          </div>
          <div className='calculator__savings-block'>
            <div className="calculator__savings-eyebrow">Save</div>
            <div className="calculator__savings-amount">
              <sup className='calculator__savings-amount--currency'>$</sup>
              {recommended.yearlySavings.toFixed(0)}
            </div>
            <div className='calculator__savings-time'>{content.savingsYearly}</div>
            {/* <div className="calculator__savings-plan">{content.savingsPlan}</div> */}
          </div>
        </div>
        <div className='calculator__recommended-plan'>
          <h3 className='calculator__recommended-headline'>{content.recommendHeadline}</h3>
          <div className='calculator__plan-block'>
            {calculationValues.dataUsage.unlimitedLines > 0 && (
              <div className='calculator__plan-breakdown'>
                <span className='calculator__plan-name'>
                  <span>
                    {calculationValues.dataUsage.unlimitedLines} {calculationValues.dataUsage.unlimitedLines === 1 ? 'Line' : 'Lines'}{' '}
                  </span>
                  | <span className='calculator__plan-type'>Unlimited</span>
                </span>
              </div>
            )}
          </div>
          <div className="personalized">
            <p className='personalized__title'>Plan includes:</p>
            <ul className="personalized__list">
              <li className="personalized__item">
                <img src={icon5GSimple} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perk5G}
              </li>
              <li className="personalized__item">
                <img src={iconWifiTower} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perk30}
              </li>
              <li className="personalized__item">
                <img src={iconPlay} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perkStreamingStandard}
              </li>
            </ul> 
            <a
                className='personalized__login'
                href={content.savingsLoginLinkUrl}
                 onClick={() => trackEvent({
                  pageName: `xmobile|savingscalculator|savings confirmation`,
                  eventAction: content.savingsLoginLink,
                  scSelection: `SC:savings=${recommended.monthlySavings.toFixed(2)}|recplan:amount=${recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
                 })}
                target="_blank"
                rel="noreferrer"
              >
                {content.savingsLoginLink}
            </a>
          </div>
        </div>
        <div className='calculator__button-wrap calculator__button-wrap--bg-grey'>
          <div className='flex-wrapper'>
            <a
              className='button button__primary'
              href={content.shopDealsURL}
              target='_blank'
              rel='noreferrer'
              onClick={() => trackEvent({
                pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                eventAction: content.shopDeals,
                scSelection: `SC:savings=${recommended.monthlySavings.toFixed(2)}|recplan:amount=${recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
              })}
            >
              {content.shopDeals}
            </a>

            <a
              className='button button__secondary'
              href={content.bringYourPhoneURL}
              target='_blank'
              rel='noreferrer'
              onClick={() => trackEvent({
                pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                eventAction: content.bringYourPhone,
                scSelection: `SC:savings=${recommended.monthlySavings.toFixed(2)}|recplan:amount=${recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
              })}
            >
              {content.bringYourPhone}
              </a>
            <button
              className='button__tertiary'
              onClick={() => {
                trackEvent({
                  pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                  eventAction: content.resetCalculator,
                  scSelection: `SC:savings=${recommended.monthlySavings.toFixed(2)}|recplan:amount=${recommended.total.toFixed(2)}:unlimitedlines=${calculationValues.dataUsage.unlimitedLines}:unlimitedpluslines=${calculationValues.dataUsage.unlimitedPlusLines}`
                });
                resetCalculator();
              }}
            >
              <span>{content.resetCalculator}</span>
            </button>
          </div>
        </div>
      </section>
       <section className={`calculator__show-savings${isLoading ? ' calculator__show-savings--is-loading' : ''}`}>
        <div className='calculator__recommended-plan'>
          <h3 className='calculator__recommended-headline'>{content.secondaryRecommendHeadline}</h3>
          <div className='calculator__plan-block'>
              <div className='calculator__plan-breakdown'>
                <span className='calculator__plan-name'>
                  <span>
                    {totalLines} {totalLines === 1 ? 'Line' : 'Lines'}{' '}
                  </span>
                  | <span className='calculator__plan-type'>Unlimited Plus</span>
                </span>
              </div>
          </div>
          <div className="personalized">
            <p className='personalized__title'>Plan includes:</p>
            <ul className="personalized__list">
              <li className="personalized__item">
                <img src={icon5GSimple} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perk5G}
              </li>
              <li className="personalized__item">
                <img src={iconWifiTower} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perk50}
              </li>
              <li className="personalized__item">
                <img src={iconPlay} alt='' role='presentation' />
                {content.optimizationTesting.personalizedSavingsPage.perkStreamingHD}
              </li>
            </ul>
          </div>
        </div>
      </section>

      <ResultsFooter content={content} calculationValues={calculationValues} competitor={competitor} dtmData={dtmData} />

    </>
  );
};

export default ShowSavings;
