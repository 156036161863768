import ReactSelect from 'react-select';
import Select from '../forms/Select';

const MonthlyBill = ({
  content,
  monthlyBill,
  setMonthlyBill,
  currentProvider,
  setCurrentProvider,
  selectValues,
  defaultOption,
  defaultProvider,
  dtmData,
}) => {
  const handleMonthlyBillChange = e => {
    const inputValStr = e.target.value
    const inputValNum = parseFloat(inputValStr);
    if (isNaN(inputValNum)) {
      setMonthlyBill(0);
    } else {
      setMonthlyBill(inputValNum.toFixed(2))
    }
  };
  return (
    <>  
      <label className='calculator__form--label' htmlFor='monthlyBill'>
        {content.monthlyBillLabel}
      </label>
      <div className='calculator__form--input-wrap monthly-bill_input-wrap'>
        <div className='calculator__monthly-fee'>
          <span className='calculator__bill-disclaimer' id='billDisclaimer'>
            {content.monthlyBillDisclaimer}
          </span>
          <div className='calculator__form--symbol-input-wrap'>
            <span className='calculator__form--dollar-sign'>&#36;</span>
            <input
              placeholder='0'
              aria-describedby='billDisclaimer'
              className='calculator__form--input'
              id='monthlyBill'
              name='monthlyBill'
              autoComplete='off'
              onChange={handleMonthlyBillChange}
              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
              max={9999}
              min={0}
              type='number'
              inputMode='numeric'
              step='0.01'
              pattern={'/^[0-9]{0,6}[^a-zA-Z](.[0-9][0-9])?$/mg'}
              defaultValue={monthlyBill > 0 && monthlyBill}
            />
          </div>
        </div>        
        <div className='calculator__lines-wrap calculator__monthly-provider'>
          <label
            className='calculator__select-label'
            htmlFor='currentProvider'
          >
            Current mobile provider
          </label>
          <div className='calculator__select-wrap select-wrap__monthly-provider'>
            {dtmData.isDesignVariation ? (
              <ReactSelect
                className='react-select__container'
                classNamePrefix='react-select'
                defaultValue={defaultOption}
                id={currentProvider}
                name={currentProvider}
                onChange={(e) => e && setCurrentProvider(e.value)}
                options={selectValues}
                // menuIsOpen={true}
                isSearchable={false}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '#f6f6f9',
                    primary: '#474747',
                  },
                })}
              />
            ) : (
              <Select
                options={content.currentProviderArray}
                metaData={{ id: 'currentProvider' }}
                handleChange={(e) => setCurrentProvider(e.target.value)}
                defaultProvider={defaultProvider}
                values={selectValues}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyBill;
