import { useRef, useEffect, useState, useCallback } from 'react';

const DataUsage = ({content, value, setValue}) => {
  const inputRef: any = useRef(null);
  const min: number = 1;
  const max: number = 3;

  const [browserWidth, setWidth] = useState(window.innerWidth);

  const handleChange = useCallback((isBrowserWidthChange = true) => {
    const sliderBarColor = '#6138F5';
    const sliderTrackColor = "#F6F6F9";
    const offsetDesktop = '119px';
    const offsetMobile = '41px';
    const mediumBreakpoint = 768;
    let offset = browserWidth > mediumBreakpoint ? offsetDesktop : offsetMobile;
    setValue(Number(inputRef.current.value), isBrowserWidthChange);

    if (value === 1) {
      inputRef.current.style.background = `linear-gradient(to right, ${sliderBarColor} 0%, ${sliderBarColor} ${offset}, ${sliderTrackColor} ${offset}, ${sliderTrackColor} 100%)`;
    } else {
      inputRef.current.style.background = `linear-gradient(to right, ${sliderBarColor} 0%, ${sliderBarColor} ${(value - min) / (max - min) * 100}%, ${sliderTrackColor} ${(value - min) / (max - min) * 100}%, ${sliderTrackColor} 100%)`;
    }
  }, [browserWidth, value, setValue]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleChange();
  }, [browserWidth, value, handleChange]);

  return (
    <>
      <div className='calculator__data-usage range-fancy'>
        <label className="calculator__form--label" htmlFor="range-fancy">{content.dataUsageHeading}</label>
        <div className="range-fancy__sub-label">
          <span className={"range-fancy__sub-label--left " + (value === 1? "range-fancy__sub-label--active": "")}>Light</span>
          <span className={(value === 2? "range-fancy__sub-label--active": "")}>Moderate</span>
          <span className={"range-fancy__sub-label--right "+ (value === 3? "range-fancy__sub-label--active": "")}>Heavy</span>
        </div>
        <input ref={inputRef} onChange={() => {handleChange(false)}} className="range-fancy__input" id="range-fancy" min={min} max={max} defaultValue={value} step="1" type="range"  />
      </div>
    </>
  );
};

export default DataUsage;
