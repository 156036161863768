import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss';
import { AppRouter } from './router';
import reportWebVitals from './reportWebVitals';
import { getAdobeTeamUtils, fetchAdobeTeamUtils } from './utils/adobe';

declare global {
  interface Window {
    internalTest: string;
  }
}

const renderApp = (dtmData) => {
  /**
   * Check for internal-test parameters (cf. public/index.html).
   * Used when need to test variations prior to Adobe Target tests.
   */
  if (window.internalTest) {
    const params = new URLSearchParams(document.location.search);
    switch (window.internalTest) {
      case 'new-design':
        dtmData.isDesignVariation = true;
        break;
      case 'ab-test':
        dtmData.isABTest = true;
        break;
      case 'savings-promo':
        const variation = params.get('variation');
        dtmData.savingsPromo = variation;
        break;
      case 'more-reasons-to-switch-banner':
        const banner = params.get('banner');
        dtmData.moreReasonsToSwitchBanner = banner;
        break;
      case 'spanish-subdomain':
        dtmData.isSpanish = true;
        break;
    }
  }

  ReactDOM.render(<AppRouter dtmData={dtmData} />, document.getElementById('root'));
};

const init = () => {
  getAdobeTeamUtils().then(fetchAdobeTeamUtils).then(renderApp).catch(renderApp);
};

init();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
