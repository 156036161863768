import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { trackCompetitorLabel, trackEvent } from '../../utils/utils';
import { initialCalculationValues } from '../../utils/calculator';
import { Helmet } from 'react-helmet';
import ResultsFooter from "../reusable/ResultsFooter";

// showAlt is the final page template used when no savings are available according to the calculations (cf. ShowSavings.tsx)
const ShowAlt = ({ content, calculationValues, setCalculationValues, setPast, setStage, dtmData }) => {

  const { competitor } = useParams<{ competitor?: string }>();

  // initial load
  useEffect(() => {
    // analytics
    trackEvent({
      pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
      eventAction: 'Savings Confirmation',
      scSelection: 'SC:savings=no savings',
      isPageLoadVariables: true,
    });
  }, [competitor]);

  const resetCalculator = () => {
    setCalculationValues(() => initialCalculationValues);
    setPast('savings');
    setStage('currentPlan');
  };

  return (
    <>
      <Helmet>
        <title>{content.metaTitle}</title>
      </Helmet>
      <section className='calculator__page-heading calculator__page-heading--savings-alt'>
        <h1 className='calculator__secondary-heading'>
          {content.showAltHeading}
          <span className='calculator__subtitle'>{content.altSubtitle}</span>
        </h1>

        {dtmData.isDesignVariation ? (
          <div className='calculator__button-wrap alt calculator__button-wrap--bg-grey'>
            <a
              className='button'
              href={content.altCtaURL}
              onClick={() => trackEvent({
                pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                eventAction: content.altCta,
                scSelection: "SC:savings=no savings"
              })}
              target="_blank"
              rel="noreferrer"
            >
                {content.altCta}
            </a>
            <button
              className='button__tertiary'
              onClick={() => { 
                trackEvent({
                  pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                  eventAction: content.resetCalculator,
                  scSelection: "SC:savings=no savings"
                });
                resetCalculator();
              }}
            >
              <span>{content.resetCalculator}</span>
            </button>
          </div>
        ) : (
          <h2 className='calculator__help-headline'>
            {content.helpHeadline}
            <span className='calculator__help-number'>
                <a
                  href={content.altHelpLinkUrl}
                  target='_blank' rel='noreferrer'
                  onClick={() => trackEvent({
                    pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                    eventAction: content.altHelpLinkLabel,
                    scSelection: "SC:savings=no savings"
                  })}
                >
                {content.altHelpLinkLabel}
              </a>
            </span>
          </h2>
        )}
      </section>

      <div className='calculator__show-alt-wrap'>
        {dtmData.isDesignVariation ? (
          <h2 className='calculator__help-headline'>
            {content.helpHeadline}
            <span className='calculator__help-number'>
              <a
                href={content.altHelpLinkUrl}
                target='_blank'
                rel='noreferrer'
                onClick={() => trackEvent({
                  pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                  eventAction: content.altHelpLinkLabel,
                  scSelection: "SC:savings=no savings"
                })}
              >
                {content.altHelpLinkLabel}
              </a>
            </span>
          </h2>
        ) : (
          <div className='calculator__button-wrap alt calculator__button-wrap--bg-grey'>
              <a
                className='button'
                href={content.altCtaURL}
                onClick={() => trackEvent({
                  pageName: `xmobile|savingscalculator|savings confirmation${trackCompetitorLabel(competitor)}`,
                  eventAction: content.altCta,
                  sscSelection: 'SC:savings=no savings'
                })}
                target="_blank"
                rel="noreferrer"
              >
              {content.altCta}
            </a>
            <button className='button__tertiary' onClick={() => resetCalculator()}>
              <span>{content.resetCalculator}</span>
            </button>
          </div>
        )}
      </div>

      <ResultsFooter content={content} calculationValues={calculationValues} competitor={competitor} dtmData={dtmData} />

    </>
  );
};

export default ShowAlt;
