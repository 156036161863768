import {useEffect} from 'react';

/**
 * Logic for handling 404 maintained by application logic and not AWS
 *
 * @param props
 * @constructor
 */
export const PageNotFound = props => {

  useEffect(() => {
    if(props.status === 404) {
      window.location.href = 'https://www.xfinity.com/mobile/404';
    }
  }, [props.status]);

  return (
    <h2>Page Not Found</h2>
  );
}