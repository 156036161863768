import { ContentContextProvider } from '../lib/ContentContext';
import { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getData } from '../data/api';

const CalculatorLayout: FunctionComponent<{}> = (props) => {
  const [contentLoaded, setContentLoaded] = useState(false);
  const [allContent, setAllContent] = useState({});
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const { competitor } = useParams<{ competitor?: string }>();
  const data = getData(competitor)

  const resetContent = (src) => setAllContent(src);

  useEffect(() => {
    resetContent(data);
    setContentLoaded(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (contentLoaded) {
    return (
      <ContentContextProvider value={allContent}>
        <div className='app-wrapper'>
          <Helmet>
            <meta charSet='utf-8' />
            <meta name='description' content={data.main.metaDescription} />
            <link rel='icon' href='https://img.xfinitymobile.com/image/upload/client/favicon.ico' />
            <link rel='apple-touch-icon' href='https://img.xfinitymobile.com/image/upload/f_auto,q_auto,fl_lossy/client/apple-touch-icon.png'></link>
            <meta name='apple-itunes-app' content='app-id=1194745615' />
            <meta property='og:type' content='website' />
            <meta property='og:site_name' content='XFINITY' />
            <meta property='og:image' content='https://www.xfinity.com/mobile-static/xfinitymobile-logo.jpg' />
            <meta property='og:url' content={url} />
            <meta property='og:description' content={data.main.metaDescription} />
            <meta property='og:title' content={data.main.metaTitle} />
            <meta name='twitter:card' content='summary' />
            <meta name='twitter:site' content='@xfinitymobile' />
            <meta name='twitter:image' content='https://www.xfinity.com/mobile-static/xfinitymobile-logo.jpg' />
            <meta name='twitter:url' content={url} />
            <meta name='twitter:description' content={data.main.metaDescription} />
            <meta name='twitter:title' content={data.main.metaTitle} />
          </Helmet>

          {props.children}
        </div>
      </ContentContextProvider>
    );
  } else return null;
};

export default CalculatorLayout;
