import { useState, useLayoutEffect } from 'react';
import CurrentPlan from './CurrentPlan';
import ShowSavings from './ShowSavings';
import ShowAlt from './ShowAlt';

export const Base = ({ allContent, stage, setStage, dtmData, calculationValues, setCalculationValues }) => {
  const content = allContent.main;
  const [past, setPast] = useState(); //used to track where focus should go

  // for any general updates that need to happen when the stage is changed
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [stage, past]);

  switch (stage) {
    case 'currentPlan':
      return <CurrentPlan setStage={setStage} setPast={setPast} content={content} calculationValues={calculationValues} setCalculationValues={setCalculationValues} dtmData={dtmData} />;

    case 'showSavings':
      return <ShowSavings setStage={setStage} setPast={setPast} content={content} calculationValues={calculationValues} setCalculationValues={setCalculationValues} dtmData={dtmData} />;

    // showAlt is used (vs showSavings) when no savings are available according to the calculations
    case 'showAlt':
      return <ShowAlt content={content} calculationValues={calculationValues} setCalculationValues={setCalculationValues} setPast={setPast} setStage={setStage} dtmData={dtmData} />;

    default:
      return null;
  }
};
